import React, { useState } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { useDispatch, useSelector } from "react-redux";
import { getProfileDetail } from "action/Settings/profile";
import { getTimeZoneList } from "action/Settings/profile";
import {
  inputBorderColor,
  inputTextColor,
  ROLES,
} from "../../components/Common/constants";
import Select from "react-select";
import { updateProfile } from "action/Settings/profile";
import NotificationAlert from "react-notification-alert";
import { resendVerifyEmail } from "action/Settings/profile";
import Warning from "components/Common/Warning";
import { emailRegex } from "components/CommonFunctions";
import { resetProfilePassword } from "action/profile/resetPassword";
import Swal from "sweetalert2";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.selectProps.className?.includes("is-invalid")
      ? "red"
      : inputBorderColor,
    height: "2.8rem",
    fontSize: "0.85rem",
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: "none",
  }),
  singleValue: (provided) => ({
    ...provided,
    color: inputTextColor,
  }),
  menuList: (provided) => ({
    ...provided,
    fontSize: "0.8rem",
  }),
};

function Profile() {
  const dispatch = useDispatch();
  const profileDetails = useSelector((state) => state.Profile);
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    timeZoneID: 0,
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    timeZone: "",
  });
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [passModal, setPassModal] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);
  const [passwordVisible, setPasswordVisible] = React.useState({
    isVerifyPasswordVisible: false,
    isConfirmPasswordVisible: false,
    isNewPasswordVisible: false,
    isCurrentPasswordVisible: false,
  });
  const [disableButton, setDisableButton] = React.useState(true);
  const [enableVerifyButton, setEnableVerifyButton] = React.useState(true);
  const [changePasswordFields, setChangePasswordFields] = React.useState({
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isCurrentPasswordValid: true,
    isNewPasswordValid: true,
    isConfirmPasswordValid: true,
  });
  const notificationAlertRef = React.useRef(null);
  const notify = (type, message) => {
    if (isNotificationOpen) return;
    setIsNotificationOpen(true);

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };

  React.useEffect(() => {
    getProfileDetail(dispatch);
    getTimeZoneList(dispatch);
  }, []);

  React.useEffect(() => {
    if (profileDetails.profileDetails && profileDetails.profileDetails.ID) {
      // Update form values with profile details
      setFormValues({
        ...formValues,
        firstName: profileDetails.profileDetails.firstName,
        lastName: profileDetails.profileDetails.lastName,
        mobile: profileDetails.profileDetails.mobile || "",
        email: profileDetails.profileDetails.email,
        timeZoneID:
          profileDetails.profileDetails?.tenantResponse?.timeZoneResponse?.ID,
      });
    }
  }, [profileDetails]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.trim();
    const finalValue = trimmedValue === "" ? "" : value;

    // Enable or disable buttons based on field name
    if (name !== "email") {
      setDisableButton(false);
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "This field is required.",
      }));
    } else {
      setEnableVerifyButton(false);
    }

    // Mobile number validation
    if (name === "mobile") {
      if (finalValue.length > 10) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "Mobile number cannot exceed 10 digits",
        }));
        // Do not update the formValues if the value is invalid
        return;
      } else {
        // Clear the error if the mobile number is valid
        setErrors((prevErrors) => ({
          ...prevErrors,
          mobile: "",
        }));
      }
    }

    // Update formValues with the valid value
    setFormValues({
      ...formValues,
      [name]: finalValue,
    });

    // Call handleBlur function
    handleBlur(e);

    // Validate the field after setting formValues
    validateField(name, finalValue);
  };

  // Validation function
  const validateField = (name, value) => {
    let error = "";
    const textOnlyRegex = /^[a-zA-Z]+$/;
    const alphanumericRegex = /^[a-zA-Z0-9]+$/;

    switch (name) {
      case "firstName":
        if (value.length < 2 && value) {
          error = "First name must be at least 2 characters long.";
        } else if (value.length > 15 && value) {
          error = "First name must be less than 15 characters.";
        } else if (!textOnlyRegex.test(value) && value) {
          error = "First name can only contain letters.";
        }
        break;

      case "lastName":
        if (value.length < 2 && value) {
          error = "Last name must be at least 2 characters long.";
        } else if (value.length > 15 && value) {
          error = "Last name must be less than 15 characters.";
        } else if (!textOnlyRegex.test(value) && value) {
          error = "Last name can only contain letters.";
        }
        break;

      case "organizationsName":
        if (value.length < 2 && value) {
          error = "Organization name must be at least 2 characters long.";
        } else if (value.length > 50 && value) {
          error = "Organization name must be less than 50 characters.";
        } else if (!alphanumericRegex.test(value) && value) {
          error = "Organization name can only contain letters and numbers.";
        }
        break;

      case "email":
        if (!value) {
          error = "Email is required.";
        } else if (!emailRegex.test(value)) {
          error = "Please enter a valid email address.";
        }
        break;

      default:
        if (!value) {
          error = "This field is required.";
        }
        break;
    }

    // Update errors state
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;

    const validateEmail = (email) => {
      return emailRegex.test(email);
    };

    const validateMobile = (mobile) => {
      const mobileRegex = /^[0-9]{10}$/;
      return mobileRegex.test(mobile);
    };

    const validateName = (name) => {
      const nameRegex = /^[A-Za-z]+$/;
      return nameRegex.test(name);
    };

    let errorMessage = "";

    if (name === "email" && !validateEmail(value)) {
      errorMessage = "Please enter a valid email address";
    } else if (name === "mobile" && !validateMobile(value) && value) {
      errorMessage = "Please enter a valid 10-digit mobile number";
    } else if (
      (name === "firstName" || name === "lastName") &&
      !validateName(value) &&
      value
    ) {
      errorMessage = "Only alphabetic characters are allowed";
    }

    setErrors({
      ...errors,
      [name]: errorMessage,
    });
  };

  const handleSelectChange = (e) => {
    setFormValues({ ...formValues, timeZoneID: e.ID });
    setDisableButton(false);
  };

  const handleSubmit = async () => {
    const profileData = {
      firstName:
        formValues.firstName === profileDetails.profileDetails.firstName
          ? ""
          : formValues.firstName,
      lastName:
        formValues.lastName === profileDetails.profileDetails.lastName
          ? ""
          : formValues.lastName,
      mobile:
        formValues.mobile === profileDetails.profileDetails.mobile
          ? ""
          : formValues.mobile,
      timeZoneID:
        formValues.timeZoneID ===
        profileDetails.profileDetails?.tenantResponse?.timeZoneResponse?.ID
          ? 0
          : formValues.timeZoneID,
    };
    const res = await updateProfile(profileData);
    if (res?.code === 200) {
      setDisableButton(true);
      setEnableVerifyButton(true);
      setIsDisable(true);
      handleModal();
      // Retrieve the user object from local storage
      const storedObject = localStorage.getItem("p");
      let user = storedObject ? JSON.parse(storedObject) : {};

      // Update the name property with the concatenated firstName and lastName
      const newName = `${formValues.firstName} ${formValues.lastName}`;
      user = {
        ...user,
        name: newName,
      };
      localStorage.setItem("p", JSON.stringify(user));
      notify("success", res.message);
    } else {
      if (Array.isArray(res.message)) notify("error", res.message[0].Msg);
      else notify("error", res.message);
    }
  };

  const handleUpdateEmail = async () => {
    const res = await updateProfile({
      email: formValues.email,
      password: formValues.password,
    });
    if (res?.code === 200) {
      setPassModal(false);
      setEnableVerifyButton(true);
      notify("success", res.message);
    } else {
      if (Array.isArray(res.message)) notify("error", res.message[0].Msg);
      else notify("error", res.message);
    }
  };

  const resendVerificationLink = async () => {
    let res = {};
    if (!enableVerifyButton) {
      setPassModal(true);
    } else {
      res = await resendVerifyEmail();
      if (res.code === 200) {
        notify("success", res.message);
      } else {
        notify("error", res.message);
      }
    }
  };

  const handleDisable = () => {
    if (!hasErrors && !isDisable) {
      return disableButton;
    } else {
      return true;
    }
  };

  const togglePasswordVisibility = (field) => {
    setPasswordVisible((prev) => ({
      ...prev,
      [field]: !passwordVisible[field],
    }));
  };

  const handleModal = () => {
    setPassModal(false);
    setFormValues({ ...formValues, password: "" });
  };

  const validatePasswordChangeFields = (name, value) => {
    setChangePasswordFields((prev) => {
      const updatedFields = { ...prev, [name]: value };

      // Validate currentPassword
      if (name === "currentPassword") {
        updatedFields.isCurrentPasswordValid = value.trim() !== "";
      }

      // Validate newPassword
      if (name === "newPassword") {
        const passwordRegix =
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+\-=[\]{}|;:'",.<>?/`~.]{8,32}$/;
        updatedFields.isNewPasswordValid =
          passwordRegix.test(value) && value.trim() !== "";
        if (updatedFields.confirmPassword !== "")
          updatedFields.isConfirmPasswordValid =
            updatedFields.newPassword === updatedFields.confirmPassword &&
            updatedFields.confirmPassword.trim() !== "";
      }

      // Validate confirmPassword whenever newPassword or confirmPassword changes
      if (name === "confirmPassword") {
        updatedFields.isConfirmPasswordValid =
          updatedFields.newPassword === updatedFields.confirmPassword &&
          updatedFields.confirmPassword.trim() !== "";
      }

      return updatedFields;
    });
  };

  //checking if any of the input is either empty or not valid
  const checkChangePasswordBtn = () => {
    if (profileDetails.profileDetails?.isPasswordEmpty)
      return Object.entries(changePasswordFields)
        .filter(([key]) => key !== "currentPassword") // Exclude currentPassword field
        .some(([key, value]) =>
          typeof value === "string" ? value === "" : !value
        );
    else
      return Object.values(changePasswordFields).some((key) =>
        typeof key === "string" ? key === "" : !key
      );
  };

  const handlePasswordChange = (e) => {
    validatePasswordChangeFields(e.target.name, e.target.value);
    const { name, value } = e.target;
    setChangePasswordFields((prev) => ({ ...prev, [name]: value.trim() }));
    checkChangePasswordBtn();
  };

  const handlePasswordSubmit = async () => {
    let newPassword = {
      oldPassword: changePasswordFields.currentPassword,
      newPassword: changePasswordFields.confirmPassword,
    };

    const res = await resetProfilePassword(newPassword);

    if (res.data?.code === 200) {
      Swal.fire({
        title: "Password Changed Successfully",
        icon: "success",
        timer: "3000",
        showCancelButton: false,
        showConfirmButton: false,
      });
      setChangePasswordFields({
        currentPassword: "",
        newPassword: "",
        confirmPassword: "",
        isCurrentPasswordValid: true,
        isNewPasswordValid: true,
        isConfirmPasswordValid: true,
      });
      setPasswordVisible({
        isVerifyPasswordVisible: false,
        isConfirmPasswordVisible: false,
        isNewPasswordVisible: false,
        isCurrentPasswordVisible: false,
      });
      getProfileDetail(dispatch);
    } else
      Swal.fire({
        title: "Something Went Wrong",
        text: res.response.data.message,
        icon: "error",
        timer: "3000",
        showCancelButton: false,
        showConfirmButton: false,
      });
  };

  const hasErrors = Object.values(errors).some((error) => error);
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <Warning />
      {/* <SimpleHeader name="Company Name" /> */}
      <Container className="mt--5" fluid>
        <>
          <>
            <Card>
              <CardHeader>
                <h3 className="mb-0 d-flex justify-content-between align-items-center">
                  <div>
                    My Profile
                    <i
                      className="fa fa-check-circle ml-2 "
                      style={{
                        color: "green",
                      }}
                    ></i>
                  </div>
                  <Button
                    outline
                    color="info"
                    onClick={() => setIsDisable(false)}
                  >
                    Edit
                  </Button>
                </h3>
              </CardHeader>
              <CardBody>
                <Row className="mt-3">
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label">First Name</label>
                      <Input
                        placeholder="First Name"
                        type="text"
                        name="firstName"
                        disabled={isDisable}
                        value={formValues.firstName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.firstName}
                      />
                      {errors.firstName && (
                        <small className="text-danger">
                          {errors.firstName}
                        </small>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label">Last Name</label>
                      <Input
                        name="lastName"
                        placeholder="Last Name"
                        type="text"
                        disabled={isDisable}
                        value={formValues.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.lastName}
                      />
                      {errors.lastName && (
                        <small className="text-danger">{errors.lastName}</small>
                      )}
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup>
                      <label className="form-control-label">Phone Number</label>
                      <Input
                        name="mobile"
                        placeholder="Phone Number"
                        type="text"
                        disabled={isDisable}
                        value={formValues.mobile}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        invalid={!!errors.mobile}
                      />
                      {errors.mobile && (
                        <small className="text-danger">{errors.mobile}</small>
                      )}
                    </FormGroup>
                  </Col>
                  {role === ROLES.tenantAdmin ? (
                    <Col md="6">
                      <FormGroup>
                        <label className="form-control-label">Time Zone</label>

                        <Select
                          options={profileDetails?.timeZone}
                          placeholder="Select Time Zone"
                          name="mailingService"
                          isDisabled={isDisable}
                          getOptionLabel={(options) =>
                            options.timezoneName + options.location
                          }
                          getOptionValue={(options) => options.ID}
                          onChange={(selected, e) =>
                            handleSelectChange(selected, e)
                          }
                          styles={customStyles}
                          value={
                            profileDetails?.timeZone?.find(
                              (option) => option.ID === formValues.timeZoneID
                            ) || ""
                          } // find the selected option based on value
                          isSearchable={true} // enable search functionality
                          className={!!errors.timeZone ? "is-invalid" : null}
                        />
                        {errors.timeZone && (
                          <small className="text-danger">
                            {errors.timeZone}
                          </small>
                        )}
                      </FormGroup>
                    </Col>
                  ) : null}
                </Row>
                <div className="d-flex justify-content-end">
                  <Button
                    color={handleDisable() ? "secondary" : "info"}
                    type="button"
                    onClick={handleSubmit}
                    disabled={handleDisable()}
                  >
                    Save
                  </Button>
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Email Verification</h3>
              </CardHeader>
              <CardBody>
                <Row md={12} style={styles.rowStyle}>
                  <Col md="6">
                    <FormGroup style={{ position: "relative" }}>
                      <label className="form-control-label">Email</label>

                      <div style={styles.rowStyle}>
                        {/* Input Field */}
                        <Input
                          name="email"
                          disabled={
                            profileDetails.profileDetails.status === "ACTIVE"
                          }
                          placeholder="Email"
                          type="text"
                          value={formValues.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          invalid={!!errors.email}
                          style={{ paddingRight: "120px" }}
                        />
                        {profileDetails.profileDetails.status !== "ACTIVE" ? (
                          <>
                            <div style={styles.verticalLine}></div>
                            <Button
                              color="link"
                              style={styles.button}
                              disabled={hasErrors}
                              onClick={resendVerificationLink}
                            >
                              {!enableVerifyButton ? " Update" : "Verify"}
                            </Button>
                          </>
                        ) : null}
                      </div>
                      {errors.email && (
                        <small className="text-danger">{errors.email}</small>
                      )}
                    </FormGroup>
                  </Col>
                </Row>{" "}
              </CardBody>
            </Card>
            <Card>
              <CardHeader>
                <h3 className="mb-0">Change Password</h3>
              </CardHeader>
              <CardBody>
                <Row md={12} style={styles.rowStyle}>
                  <Col md="12">
                    <FormGroup style={{ position: "relative" }}>
                      <Row className="pb-4 d-flex flex-column">
                        {!profileDetails.profileDetails?.isPasswordEmpty && (
                          <Col sm={12} md={6} className={"pb-md-2 pb-4"}>
                            <label className="form-control-label">
                              Current Password
                            </label>
                            <InputGroup>
                              <Input
                                onCopy={(e) => e.preventDefault()}
                                onCut={(e) => e.preventDefault()}
                                onPaste={(e) => e.preventDefault()}
                                autoComplete="cc-password"
                                name="currentPassword"
                                type={
                                  !passwordVisible.isCurrentPasswordVisible
                                    ? "password"
                                    : "text"
                                }
                                invalid={
                                  !changePasswordFields.isCurrentPasswordValid
                                }
                                onChange={(e) => {
                                  handlePasswordChange(e);
                                }}
                                onBlur={(e) =>
                                  validatePasswordChangeFields(
                                    e.target.name,
                                    e.target.value
                                  )
                                }
                                value={changePasswordFields.currentPassword}
                              />
                              <InputGroupAddon addonType="append">
                                <InputGroupText
                                  className={
                                    !changePasswordFields.isCurrentPasswordValid
                                      ? "border border-danger rounded-right"
                                      : ""
                                  }
                                >
                                  <i
                                    className={`fa ${
                                      !passwordVisible.isCurrentPasswordVisible
                                        ? "fa-eye-slash"
                                        : "fa-eye"
                                    }`}
                                    aria-hidden="true"
                                    onClick={(e) =>
                                      togglePasswordVisibility(
                                        "isCurrentPasswordVisible"
                                      )
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </InputGroupText>
                              </InputGroupAddon>
                              {!changePasswordFields.isCurrentPasswordValid && (
                                <p className="invalid-feedback mb--1">
                                  Current password cannot be empty
                                </p>
                              )}
                            </InputGroup>
                            <small>
                              Note: Password must be at least 8 characters long
                              and include at least one uppercase and lowercase
                              letter and one number
                            </small>
                          </Col>
                        )}

                        <Col
                          sm={12}
                          md={
                            !profileDetails.profileDetails?.isPasswordEmpty
                              ? 6
                              : 6
                          }
                          className={"pb-md-4 pb-4"}
                        >
                          <label className="form-control-label">
                            New Password
                          </label>
                          <InputGroup>
                            <Input
                              onCopy={(e) => e.preventDefault()}
                              onCut={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              maxLength={32}
                              autoComplete="cc-password"
                              name="newPassword"
                              type={
                                !passwordVisible.isNewPasswordVisible
                                  ? "password"
                                  : "text"
                              }
                              invalid={!changePasswordFields.isNewPasswordValid}
                              onChange={(e) => {
                                handlePasswordChange(e);
                              }}
                              onBlur={(e) =>
                                validatePasswordChangeFields(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              value={changePasswordFields.newPassword}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                className={
                                  !changePasswordFields.isNewPasswordValid
                                    ? "border border-danger rounded-right"
                                    : ""
                                }
                              >
                                <i
                                  className={`fa ${
                                    !passwordVisible.isNewPasswordVisible
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={(e) =>
                                    togglePasswordVisibility(
                                      "isNewPasswordVisible"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            {!changePasswordFields.isNewPasswordValid && (
                              <p className="invalid-feedback mb--4">
                                Please create a stronger password
                              </p>
                            )}
                          </InputGroup>
                        </Col>
                        <Col
                          className={"pb-md-0 pb-4"}
                          sm={12}
                          md={
                            !profileDetails.profileDetails?.isPasswordEmpty
                              ? 6
                              : 6
                          }
                        >
                          <label className="form-control-label">
                            Confirm Password
                          </label>
                          <InputGroup>
                            <Input
                              onCopy={(e) => e.preventDefault()}
                              onCut={(e) => e.preventDefault()}
                              onPaste={(e) => e.preventDefault()}
                              maxLength={32}
                              autoComplete="cc-password"
                              name="confirmPassword"
                              type={
                                !passwordVisible.isConfirmPasswordVisible
                                  ? "password"
                                  : "text"
                              }
                              invalid={
                                !changePasswordFields.isConfirmPasswordValid
                              }
                              onChange={(e) => {
                                handlePasswordChange(e);
                              }}
                              onBlur={(e) =>
                                validatePasswordChangeFields(
                                  e.target.name,
                                  e.target.value
                                )
                              }
                              value={changePasswordFields.confirmPassword}
                            />
                            <InputGroupAddon addonType="append">
                              <InputGroupText
                                className={
                                  !changePasswordFields.isConfirmPasswordValid
                                    ? "border border-danger rounded-right"
                                    : ""
                                }
                              >
                                <i
                                  className={`fa ${
                                    !passwordVisible.isConfirmPasswordVisible
                                      ? "fa-eye-slash"
                                      : "fa-eye"
                                  }`}
                                  aria-hidden="true"
                                  onClick={(e) =>
                                    togglePasswordVisibility(
                                      "isConfirmPasswordVisible"
                                    )
                                  }
                                  style={{
                                    cursor: "pointer",
                                  }}
                                />
                              </InputGroupText>
                            </InputGroupAddon>
                            {!changePasswordFields.isConfirmPasswordValid && (
                              <p className="invalid-feedback mb--4">
                                Confirm password field must match the current
                                password field.
                              </p>
                            )}
                          </InputGroup>
                        </Col>
                      </Row>
                      <div className="mt-2 d-flex align-items-center">
                        <Button
                          onClick={handlePasswordSubmit}
                          color={
                            checkChangePasswordBtn() ? "secondary" : "info"
                          }
                          type="button"
                          disabled={checkChangePasswordBtn()}
                        >
                          Save
                        </Button>
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </>
          <Modal
            isOpen={passModal}
            toggle={handleModal}
            className={`modal-dialog-centered`}
          >
            <ModalHeader>Enter Your password</ModalHeader>
            <ModalBody>
              <InputGroup className="input-group-merge input-group-alternative mt--4 mb-3">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fa fa-lock" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="password"
                  type={
                    passwordVisible.isVerifyPasswordVisible
                      ? "text"
                      : "password"
                  }
                  placeholder="Enter the Password"
                  value={formValues?.password}
                  onChange={handleChange}
                  //
                />
                <InputGroupText>
                  <i
                    className={`fa ${
                      passwordVisible ? "fa-eye-slash" : "fa-eye"
                    }`}
                    aria-hidden="true"
                    onClick={() =>
                      togglePasswordVisibility("isVerifyPasswordVisible")
                    }
                    style={{ cursor: "pointer" }}
                  ></i>
                </InputGroupText>
              </InputGroup>
              <div className="d-flex pt-3 justify-content-end">
                <Button
                  className="mr-2"
                  color="info "
                  onClick={handleUpdateEmail}
                  disabled={!formValues.password}
                >
                  Submit
                </Button>
              </div>
            </ModalBody>
          </Modal>
        </>
      </Container>
    </>
  );
}

export default Profile;
const styles = {
  button: {
    border: "none",
    background: "none",
    boxShadow: "none",
    position: "absolute",
    right: "10px", // Position the button from the right
    top: "50%", // Center the button vertically
    transform: "translateY(-50%)", // Adjust for vertical centering
    paddingLeft: "10px", // Spacing between the vertical line and the button
    cursor: "pointer",
  },

  rowStyle: {
    display: "flex",
    alignItems: "center",
    position: "relative", // To contain the absolute positioning of the button
  },

  verticalLine: {
    width: "1px",
    height: "40px", // Match the height of the input
    backgroundColor: "#ccc",
    marginLeft: "-90px", // Spacing between input and the line
  },
};
