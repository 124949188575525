import Login from "pages/Auth/Login";
import CreateCampaignStep1 from "pages/Campaign/CreateCampaign/CreateCampaignStep1";
import CreateCampaignStep2 from "pages/Campaign/CreateCampaign/CreateCampaignStep2";
import ListCampaign from "pages/Campaign/ListCampaign";
import Dashboard from "pages/Dashboards/Dashboard";
import EmailConfiguration from "pages/Settings/EmailConfig/EmailConfiguration";
import UnsubscribeList from "pages/Unsubscriber/UnsubscribeList";
import Organization from "pages/Settings/Organization";
import AddSubUser from "pages/Settings/AddSubUser";
import Profile from "pages/Settings/Profile";
import EditEmailConfig from "pages/Settings/EmailConfig/EditEmailConfig";
import Plans from "pages/Billing/Plans";
import PaymentHistory from "pages/Billing/PlanHistory";
import CampaignDetails from "pages/Campaign/CampaignDetails/CampaignDetails";
import CalendarView from "pages/Calendar/Calendar";
import Registration from "pages/Auth/Registration";
import { ROLES } from "components/Common/constants";

const routes = [
  {
    show: true,
    path: "/dashboard",
    name: "Dashboard",
    icon: "ni ni-shop text-primary",
    component: <Dashboard />,
    layout: "/a",
    roles: [ROLES.tenantAdmin, ROLES.tenantUser, ROLES.tenantAccountManager],
  },
  {
    show: false,
    path: "/login/*",
    name: "Login",
    icon: "ni ni-ungroup text-orange",
    component: <Login />,
    layout: "/auth",
    roles: [ROLES.tenantAdmin, ROLES.tenantUser, ROLES.tenantAccountManager],
  },
  {
    show: false,
    path: "/register",
    name: "Register",
    icon: "ni ni-ungroup text-orange",
    component: <Registration />,
    layout: "/auth",
  },
  {
    show: true,
    collapse: false,
    path: "/campaigns",
    name: "Calendars",
    icon: "fa-solid fa-envelopes-bulk text-orange",
    miniName: "C",
    component: <ListCampaign />,
    layout: "/a",
    roles: [ROLES.tenantAdmin, ROLES.tenantUser, ROLES.tenantAccountManager],
  },
  {
    show: false,
    collapse: true,
    name: "Calendars",
    icon: "fa-solid fa-envelopes-bulk text-orange",
    state: "campaignCollapse",
    roles: [ROLES.tenantAdmin, ROLES.tenantUser, ROLES.tenantAccountManager],
    views: [
      {
        show: true,
        path: "/campaigns/step-1",
        name: "Create Calendar",
        miniName: "C",
        component: <CreateCampaignStep1 />,
        layout: "/a",
        roles: [
          ROLES.tenantAdmin,
          ROLES.tenantUser,
          ROLES.tenantAccountManager,
        ],
      },
      {
        show: false,
        path: "/campaigns/step-2",
        component: <CreateCampaignStep2 />,
        layout: "/a",
        roles: [
          ROLES.tenantAdmin,
          ROLES.tenantUser,
          ROLES.tenantAccountManager,
        ],
      },
      {
        show: false,
        path: "/campaigns/details",
        component: <CampaignDetails />,
        layout: "/a",
        roles: [
          ROLES.tenantAdmin,
          ROLES.tenantUser,
          ROLES.tenantAccountManager,
        ],
      },
    ],
  },
  {
    show: false,
    path: "/calendar",
    name: "Event Calendar",
    icon: "ni ni-calendar-grid-58 text-primary",
    component: <CalendarView />,
    layout: "/a",
    roles: [ROLES.tenantAdmin, ROLES.tenantUser, ROLES.tenantAccountManager],
  },
  {
    show: true,
    collapse: true,
    name: "Billing",
    icon: "fa fa-file-invoice-dollar text-green",
    state: "billingCollapse",
    role: "TenantAdmin",
    roles: [ROLES.tenantAdmin],
    views: [
      {
        show: true,
        path: "/plans",
        name: "Plans",
        miniName: "PS",
        component: <Plans />,
        layout: "/a",
        roles: [ROLES.tenantAdmin],
      },
      {
        show: true,
        path: "/payments",
        name: "Payment History",
        miniName: "PH",
        component: <PaymentHistory />,
        layout: "/a",
        roles: [ROLES.tenantAdmin],
      },
    ],
  },
  {
    collapse: true,
    show: true,
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-blue",
    state: "settingCollapse",
    roles: [ROLES.tenantAdmin, ROLES.tenantAccountManager],
    views: [
      {
        path: "/configurations",
        show: true,
        name: "Email Configuration",
        miniName: "EM",
        component: <EmailConfiguration />,
        layout: "/a",
        roles: [ROLES.tenantAdmin, ROLES.tenantAccountManager],
      },
      {
        path: "/email/edit",
        show: false,
        component: <EditEmailConfig />,
        layout: "/a",
        roles: [ROLES.tenantAdmin, ROLES.tenantAccountManager],
      },
      {
        path: "/sub-users",
        show: true,
        name: "Sub Users",
        miniName: "ASU",
        roles: [ROLES.tenantAdmin],
        component: <AddSubUser />,
        layout: "/a",
      },
      {
        path: "/organization",
        show: true,
        name: "Organization",
        miniName: "O",
        roles: [ROLES.tenantAdmin],
        component: <Organization />,
        layout: "/a",
      },
      {
        path: "/user/profile",
        show: false,
        name: "User Profile",
        roles: [
          ROLES.tenantAdmin,
          ROLES.tenantUser,
          ROLES.tenantAccountManager,
        ],
        miniName: "UP",
        component: <Profile />,
        layout: "/a",
      },
    ],
  },
  {
    show: true,
    path: "/unsubscribers",
    name: "Unsubscribers",
    icon: "ni ni-bullet-list-67 text-gray",
    component: <UnsubscribeList />,
    layout: "/a",
    roles: [ROLES.tenantAdmin, ROLES.tenantUser, ROLES.tenantAccountManager],
  },
];

export default routes;
