import { Button, Card, CardBody } from "reactstrap";

import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import BootstrapTable from "react-bootstrap-table-next";
import { useEffect, useState } from "react";

import { useSelector } from "react-redux";
import { cyanBlue } from "components/Common/constants";
import { useNavigate } from "react-router-dom";
import { convertUTCToLocal } from "components/CommonFunctions";
import { ROLES } from "components/Common/constants";

function EmailConfigList() {
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const navigate = useNavigate();
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const emailConfigurationList = useSelector(
    (state) => state.Dashboard.dashboardData
  );

  useEffect(() => {
    if (
      emailConfigurationList?.emailConfigurations &&
      emailConfigurationList?.emailConfigurations?.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (
      emailConfigurationList?.emailConfigurations &&
      emailConfigurationList?.emailConfigurations?.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    } else if (emailConfigurationList?.code) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
        error: true,
      });
    }
  }, [emailConfigurationList]);

  return (
    <>
      <Card style={styles.cardStyle}>
        <CardBody className="p--2">
          <div md={12} style={styles.header}>
            <h3 className="mb-0">Email Configurations</h3>
            {role === ROLES.tenantAdmin && (
              <div>
                <Button
                  color="warning"
                  className="px-2 py-1"
                  onClick={() =>
                    navigate("/a/configurations", {
                      state: { autoFocus: true },
                    })
                  }
                >
                  Add New
                </Button>
                <Button
                  color="info"
                  className="px-2 py-1"
                  onClick={() =>
                    navigate("/a/configurations", {
                      state: { scrollList: true },
                    })
                  }
                >
                  See all
                </Button>
              </div>
            )}
          </div>
          {showListAlternative.loader ? (
            <div style={styles.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={styles.loaderColor}
              />
            </div>
          ) : showListAlternative.noRecordsFound ? (
            <div style={styles.loaderContainer}>No Records Found</div>
          ) : showListAlternative.error ? (
            <div style={styles.loaderContainer}>
              Something went wrong please try after some time.
            </div>
          ) : (
            <>
              <ToolkitProvider
                data={emailConfigurationList.emailConfigurations || []}
                keyField="ID"
                columns={[
                  {
                    dataField: "title",
                    text: "Mailing Service",
                  },
                  {
                    dataField: "createdOn",
                    text: "Created On",
                    formatter: (cell) => {
                      return convertUTCToLocal(cell); //to show the date in users local time
                    },
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive">
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
}

export default EmailConfigList;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "35vh",
  },
  loaderColor: { color: cyanBlue },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    cursor: "pointer",
  },
  cardStyle: { height: "25rem", overflow: "hidden" },
};
