/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Card, CardBody, CardTitle, Container, Row, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { ROLES } from "components/Common/constants";

function CardsHeader(props) {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const dasboard = props?.dashboardCount;
  return (
    <>
      <div className="header header-dark bg-info pb-6 content__title content__title--calendar mt--6">
        <Container fluid>
          <div className="header-body">
            <Row className="d-flex justify-content-center ml-5">
              <Col sm={3} md={4}>
                <Row className="h-75">
                  <Card className="card-stats h-100 w-75 mx-3">
                    <CardBody className="px-3">
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Calendar Sent
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 mr-1">
                            {dasboard?.calendarSent}
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-red text-white rounded-circle shadow">
                            <i className="fa fa-calendar-check" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
              <Col sm={3} md={4}>
                <Row className="h-75">
                  <Card className="card-stats h-100 w-75 mx-3">
                    <CardBody className="px-3">
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Calendars
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 mr-1">
                            {props?.dashboardCount?.campaigns}
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-green text-white rounded-circle shadow">
                            <i className="fa-solid fa-envelopes-bulk" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
              <Col sm={3} md={4}>
                <Row className="h-75">
                  <Card className="card-stats h-100 w-75 mx-3">
                    <CardBody className="px-3">
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Attendees
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 mr-1">
                            {dasboard?.totalAttendees}
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-blue text-white rounded-circle shadow">
                            <i className="fa fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>

              <Col sm={3} md={4}>
                <Row className="h-75">
                  <Card className="card-stats h-100 w-75 mx-3">
                    <CardBody className="px-3">
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Total Sub User
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 mr-1">
                            {dasboard?.totalActiveSubUsers}
                          </span>
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-orange text-white rounded-circle shadow">
                            <i className="fa fa-users" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
              <Col sm={3} md={4}>
                <Row className="h-75">
                  <Card className="card-stats h-100 w-75 mx-3">
                    <CardBody className="px-3">
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h4"
                            className="text-uppercase text-muted mb-0"
                          >
                            Available Credits
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0 mr-1">
                            {dasboard?.availableCredits}
                          </span>
                          {(role !== ROLES.tenantUser ||
                            role !== ROLES.tenantAccountManager) && (
                            <small
                              className="font-weight-normal text-info"
                              style={styles.cursor}
                              onClick={() => navigate("/a/plans")}
                            >
                              Get More Credit
                            </small>
                          )}
                        </Col>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-gradient-info text-white rounded-circle shadow">
                            <i className="fa-sharp fa-solid fa-money-bill"></i>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
              <Col sm={3} md={4}>
                <Row className="h-75">
                  <Card className="card-stats h-100 mx-3 w-75">
                    <CardBody className="px-3">
                      <Row>
                        <Col>
                          <CardTitle
                            tag="h3"
                            className="text-uppercase text-muted mb-0"
                          >
                            {dasboard?.planName}
                          </CardTitle>
                        </Col>
                        {role === ROLES.tenantAdmin && (
                          <Col className="col-auto">
                            <p
                              className="text-button h5 text-info"
                              style={styles.cursor}
                              onClick={() => navigate("/a/plans")}
                            >
                              Upgrade Plan
                            </p>
                          </Col>
                        )}
                      </Row>
                      <Row>
                        <Col>
                          <small className="mb-0 fs-">
                            Renewal Date : {dasboard?.renewalActionDate}
                          </small>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Row>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}

CardsHeader.propTypes = {
  name: PropTypes.string,
};

export default CardsHeader;
const styles = {
  cursor: {
    cursor: "pointer",
  },
};
