import React from "react";
// reactstrap components
import {
  Card,
  CardBody,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
// core components
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, {
  Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { inviteSubUser } from "action/Settings/SubUser";
import { useDispatch, useSelector } from "react-redux";
import { subUserList } from "action/Settings/SubUser";
import { deleteSubUser, deleteInvitedSubUser } from "action/Settings/SubUser";
import NotificationAlert from "react-notification-alert";
import { planList } from "action/Billing/plans";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { cyanBlue, ROLES } from "components/Common/constants";
import { Tabs, Tab, Box, Typography, AppBar, Backdrop } from "@mui/material";
import { invitedSubUserList } from "action/Settings/SubUser";
import { convertUTCToLocal } from "components/CommonFunctions";
import Warning from "components/Common/Warning";
import { emailRegex } from "components/CommonFunctions";

const { SearchBar } = Search;

function AddSubUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // Pass state from the dashboard to the open "Invite Subuser" modal
  // if the user navigates directly from the dashboard.
  const location = useLocation();
  const isDashboradTab = location.state;

  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const planData = useSelector((state) => state.Plans.planListData);
  const subUsers = useSelector((state) => state.SubUser.subUsersList);
  const [email, setEmail] = React.useState("");
  const [rowId, setRowId] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [inviteLoading, setInviteLoading] = React.useState(false);
  const [emailError, setEmailError] = React.useState("");
  const [deleteModal, setDeleteModal] = React.useState(false);
  const [addSubUserModal, setAddSubUserModal] = React.useState(
    isDashboradTab ? true : false
  );
  const notificationAlertRef = React.useRef(null);
  const [tabValue, setTabValue] = React.useState("ACTIVATED_SUB_USER");
  React.useEffect(() => {
    if (subUsers) setIsLoading(false);
  }, [subUsers]);
  React.useEffect(() => {
    if (tabValue === "ACTIVATED_SUB_USER") subUserList(dispatch);
    else invitedSubUserList(dispatch);
    setIsLoading(true);
  }, [dispatch, tabValue]);

  React.useEffect(() => {
    planList(dispatch);
  }, [dispatch]);

  const handleChange = (e) => {
    const trimmedValue = e.target.value.trim().replace(/\s+/g, "");
    setEmail(trimmedValue);
    handleBlur({ ...e, target: { ...e.target, value: trimmedValue } });
  };

  const handleList = () => {
    if (subUsers?.data?.User) {
      return subUsers?.data?.User;
    } else {
      return subUsers?.data?.listEmailInvite;
    }
  };
  const handleBlur = (e) => {
    if (e.target.value === "") setEmailError("This field is required.");
    else setEmailError("");
  };

  const validateForm = () => {
    let isValid = true;

    // Email Validation: Check if the email is present and properly formatted
    if (!email) {
      setEmailError("This field is required");
      isValid = false;
    } else if (!emailRegex.test(email)) {
      setEmailError("Please enter a valid email address");
      isValid = false;
    }

    return isValid;
  };

  const statusCellRenderer = (cell) => {
    return <span style={{ color: "green" }}>{cell}</span>;
  };

  const inviteUser = async () => {
    if (validateForm()) {
      setInviteLoading(true);
      const res = await inviteSubUser({ email });
      if (res.code === 200) {
        setInviteLoading(false);
        Swal.fire({
          position: "middle",
          icon: "success",
          title: "Sub user added successfully",
          text: res.message,
          showConfirmButton: false,
          timer: 3000,
        });
        handleInviteUser();
        setEmail("");
        if (tabValue === "INVITED_SUB_USER") invitedSubUserList(dispatch);
        else setTabValue("INVITED_SUB_USER");
      } else {
        setInviteLoading(false);
        Swal.fire({
          position: "middle",
          icon: "error",
          title: res.code + "Error",
          text: res.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };
  const resendInvite = async (email) => {
    if (email) {
      setInviteLoading(true);
      const res = await inviteSubUser({ email });
      setInviteLoading(false);
      if (res.code === 200) {
        Swal.fire({
          position: "middle",
          icon: "success",
          title: "Resent successfully",
          text: res.message,
          showConfirmButton: false,
          timer: 3000,
        });
        setEmail("");
      } else {
        Swal.fire({
          position: "middle",
          icon: "error",
          title: res.code + " Error",
          text: res.message,
          showConfirmButton: false,
          timer: 3000,
        });
      }
    }
  };

  const handleModalChange = (ID) => {
    setRowId(ID);
    setDeleteModal(!deleteModal);
  };

  const handleDelete = async () => {
    setInviteLoading(true);
    const res =
      tabValue === "INVITED_SUB_USER"
        ? await deleteInvitedSubUser(rowId)
        : await deleteSubUser(rowId);
    setInviteLoading(false);
    if (res.code === 200) {
      Swal.fire({
        position: "middle",
        icon: "success",
        title: "Sub user Deleted",
        text: res.message,
        showConfirmButton: false,
        timer: 3000,
      });
      handleModalChange();
      if (tabValue === "INVITED_SUB_USER") invitedSubUserList(dispatch);
      else subUserList(dispatch);
    } else {
      Swal.fire({
        position: "middle",
        icon: "error",
        title: res.code + " Error",
        text: res.message,
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  const handleInviteUser = () => {
    setAddSubUserModal(!addSubUserModal);
    setEmailError("");
    setEmail("");
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Warning />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} // Ensures it's on top of other elements
        open={inviteLoading}
      >
        <i className="fa fa-spinner fa-spin fa-3x text-black" />
      </Backdrop>

      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <SimpleHeader name="Company Name" /> */}
      <Container className="mt--6" fluid>
        <Card className="mb-4">
          <CardBody>
            <Modal isOpen={addSubUserModal} className="modal-dialog-centered">
              <ModalHeader toggle={handleInviteUser} className=" bg-secondary">
                <p className="mb-2 h3">Invite Sub User</p>
              </ModalHeader>
              {planData?.tenantActivePlan?.planID !== 1 ? (
                <ModalBody className="mt--4 bg-secondary">
                  <Row className="d-flex align-items-center mb-5">
                    <Col md="12">
                      <FormGroup className="mb-0 position-relative">
                        <label className="form-control-label">Email</label>
                        <Input
                          id="example3cols2Input"
                          value={email}
                          name="email"
                          className={emailError ? "is-invalid" : ""}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          placeholder="Email"
                          type="text"
                        />
                        <div>
                          {emailError && (
                            <small className="text-danger position-absolute">
                              {emailError}
                            </small>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="6" className="mb--4"></Col>
                  </Row>
                </ModalBody>
              ) : (
                <ModalBody className="mt--4 bg-secondary">
                  <p>
                    The Free Plan doesn't support adding users. Click below to
                    upgrade.
                  </p>
                </ModalBody>
              )}

              <ModalFooter className="mt--5 bg-secondary">
                <Button
                  color={
                    !email && planData?.tenantActivePlan?.planID !== 1
                      ? "lightGray"
                      : "info"
                  }
                  type="submit"
                  disabled={!email && planData?.tenantActivePlan?.planID !== 1}
                  onClick={() => {
                    if (planData?.tenantActivePlan?.planID !== 1) {
                      inviteUser();
                    } else {
                      navigate("/a/plans");
                    }
                  }}
                >
                  {planData?.tenantActivePlan?.planID !== 1
                    ? " Send Invite"
                    : "Upgrade Plan"}
                </Button>
              </ModalFooter>
            </Modal>
            <div md={12} style={styles.header}>
              <h3 className="mb-0">Sub Users</h3>
              {role === ROLES.tenantAdmin ? (
                <Button color="info" onClick={() => setAddSubUserModal(true)}>
                  Invite Sub User
                </Button>
              ) : null}
            </div>
            <Row lg="12" md="12" sm="12">
              <Col lg="12" md="12" sm="12">
                <Tabs
                  variant="fullWidth"
                  value={tabValue}
                  onChange={handleTabChange}
                  className="border-bottom"
                >
                  <Tab label="Active Sub Users" value="ACTIVATED_SUB_USER" />
                  <Tab label="Invited Sub Users" value="INVITED_SUB_USER" />
                </Tabs>
              </Col>
            </Row>
            {isLoading ? (
              <div style={styles.loaderContainer}>
                <i
                  className="fa fa-spinner fa-spin fa-2xl"
                  style={styles.loaderColor}
                />
              </div>
            ) : subUsers?.code !== 200 ? (
              <div style={styles.loaderContainer}>
                Something went wrong please try after some time.
              </div>
            ) : handleList()?.length !== 0 ? (
              <ToolkitProvider
                data={
                  subUsers?.data?.User
                    ? subUsers?.data?.User
                    : subUsers?.data?.listEmailInvite
                    ? subUsers?.data?.listEmailInvite
                    : []
                }
                keyField="ID"
                columns={[
                  {
                    dataField: "firstName",
                    text: "First Name",
                    sort: true,
                    hidden: tabValue === "INVITED_SUB_USER",
                  },
                  {
                    dataField: "lastName",
                    text: "Last Name",
                    sort: true,
                    hidden: tabValue === "INVITED_SUB_USER",
                  },
                  {
                    dataField: "email",
                    text: "Email",
                    sort: true,
                  },
                  {
                    dataField: "createdAt",
                    text:
                      tabValue === "INVITED_SUB_USER"
                        ? "Invited At"
                        : "Registered On",
                    formatter: (cell) => {
                      return convertUTCToLocal(cell); //to show the date in users local time
                    },
                    sort: true,
                  },
                  {
                    dataField: "status",
                    text: "Status",
                    sort: true,
                    formatter: statusCellRenderer,
                    hidden: tabValue === "INVITED_SUB_USER",
                  },
                  {
                    dataField: "Action",
                    text: "Action",
                    formatter: (cell, row) => (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          columnGap: "1rem",
                        }}
                      >
                        {tabValue === "INVITED_SUB_USER" ? (
                          <>
                            <a
                              className="table-action table-action-resend"
                              id="tooltip12475021"
                              onClick={() => resendInvite(row.email)}
                            >
                              <i className="fas fa-paper-plane" />
                            </a>
                            <p>|</p>
                            <UncontrolledTooltip
                              delay={0}
                              target="tooltip12475021"
                            >
                              Resend Invite
                            </UncontrolledTooltip>
                          </>
                        ) : null}

                        <a
                          className="table-action table-action-delete"
                          id="tooltip12475020"
                          onClick={() =>
                            handleModalChange(
                              tabValue === "INVITED_SUB_USER" ? row.id : row.ID
                            )
                          }
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip delay={0} target="tooltip12475020">
                          Delete SubUser
                        </UncontrolledTooltip>
                      </div>
                    ),
                  },
                ]}
                search
              >
                {(props) => (
                  <div className="py-4 table-responsive ">
                    <div
                      id="datatable-basic_filter"
                      className="dataTables_filter px-4 pb-1"
                    >
                      <label>
                        Search:
                        <SearchBar
                          className="form-control-sm"
                          placeholder=""
                          {...props.searchProps}
                        />
                      </label>
                    </div>
                    <div className="d-flex justify-content-center mr-9"></div>
                    <BootstrapTable
                      {...props.baseProps}
                      bootstrap4={true}
                      // pagination={pagination}
                      bordered={false}
                    />
                  </div>
                )}
              </ToolkitProvider>
            ) : (
              <div style={styles.loaderContainer}>
                No sub users {subUsers?.data?.User ? "added" : "invited"} yet.
              </div>
            )}
          </CardBody>
        </Card>
        <Modal
          isOpen={deleteModal}
          toggle={handleModalChange}
          className="modal-dialog-centered modal-secondary"
        >
          <div className="modal-body">
            Are you sure you want to delete this sub user?
          </div>
          <div className="modal-footer d-flex justifyContent-right">
            <Button
              // className="ml-auto"
              color="info"
              type="button"
              outline
              onClick={handleModalChange}
            >
              Close
            </Button>
            <Button
              // className="ml-auto"
              color="error"
              outline
              type="button"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </Modal>
      </Container>
    </>
  );
}

export default AddSubUser;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
};
