export const inputBorderColor = "#dee2e6";
export const inputTextColor = "#8898aa";
export const cyanBlue = "#11cdef";
export const selectedColor = "#f6f9fc";
export const statusColor1 = "#31928b";
export const primaryConfigColor = "#1aae6f";

//youtube embeded links

//View link of create campaign
export const campaignGuide =
  "https://www.youtube.com/embed/zwLZqUucEr8?si=i6p9uPm6I0boeXs7?&rel=0";

//Campaign status constant for IN-PROGRESS
export const CAMPAIGN_STATUS_IN_PROGRESS = "IN-PROGRESS";

export const USD = "USD";
export const INR = "INR";

//roles
export const ROLES = {
  tenantAdmin: "TENANT_ADMIN",
  tenantUser: "TENANT_USER", 
  tenantAccountManager: "TENANT_ACCOUNT_MANAGER", //TENANT_ACCOUNT_MANAGER new role introducted to make the configuration related route available
};
