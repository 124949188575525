/* eslint-disable no-undef */
import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Container,
  Row,
  Col,
  Button,
  DropdownToggle,
  Media,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CardFooter,
  Label,
  ButtonGroup,
  CardText,
  CardTitle,
  Input,
  Table,
} from "reactstrap";
import { CardActions, Divider } from "@mui/material";
import { storePlanData } from "action/Billing/plans";
import { useDispatch, useSelector } from "react-redux";
import { planList } from "action/Billing/plans";
import { checkPaymentStatus } from "action/Billing/plans";
import CancelPlanModal from "./CancelModal";
import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";
import { createOrder } from "action/Billing/plans";
import { renewalRequired } from "action/Billing/plans";
import PaypalButton from "./Paypal";
import Warning from "components/Common/Warning";
import { planListData } from "constants/Billing/planConstant";
import { razorpayTopup } from "action/Billing/plans";
import { checkTopUpStatus } from "action/Billing/plans";
import { USD } from "components/Common/constants";
import { INR } from "components/Common/constants";
import { paypalPlanSubscription } from "action/Billing/plans";
import { paypalTopUp } from "action/Billing/plans";

function Plans() {
  // Define state to manage hover
  const dispatch = useDispatch();
  const planData = useSelector((state) => state.Plans?.planListData);
  const renewal = useSelector((state) => state.Plans.renewalRequiredData);
  const [hoveredCard, setHoveredCard] = React.useState(null);
  const [isHovered, setIsHovered] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [activeCurrency, setActiveCurrency] = React.useState(
    planData.tenantActivePlan?.currency
      ? planData.tenantActivePlan?.currency
      : ""
  );
  const [paypalPlanID, setPaypalPlanID] = React.useState(0);
  const [paypalModal, setPaypalModal] = React.useState(false);
  const [rotated, setRotated] = React.useState(false);
  const [topUp, setTopUp] = React.useState(false);
  const email = JSON.parse(localStorage.getItem("p"))?.Email;
  const [openDowngrade, setOpenDowngrade] = React.useState(false);
  const [changePlanModal, setChangePlanModal] = React.useState(false);
  const [planID, setPlanID] = React.useState(0);
  const notificationAlertRef = React.useRef(null);
  const [isNotificationOpen, setIsNotificationOpen] = React.useState(false);
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [selectedPlan, setSelectedPlan] = React.useState([]);
  const [orderFlag, setOrderFlag] = React.useState("");

  React.useEffect(() => {
    dispatch({ type: planListData, payload: {} });
    planList(dispatch);
    renewalRequired(dispatch);
  }, [dispatch]);

  React.useEffect(() => {
    if (planData) {
      setActiveCurrency(
        planData.tenantActivePlan?.currency
          ? planData.tenantActivePlan?.currency
          : USD
      );
    }
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [planData]);

  const notify = (type, message) => {
    if (isNotificationOpen) return; // Do nothing if notification is already open

    setIsNotificationOpen(true); // Set the notification as open

    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message}
          </span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };

    notificationAlertRef.current.notificationAlert(options);

    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      setIsNotificationOpen(false);
    }, 7000); // Match the autoDismiss time (7000ms = 7 seconds)
  };
  // Function to handle hover
  const handleMouseEnter = (cardName) => {
    setHoveredCard(cardName);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };
  const getColumnSize = (length, windowWidth) => {
    if (length > 4) {
      if (windowWidth <= 1060) return 6;
      if (windowWidth <= 1260) return 4;
      return 3;
    } else {
      return 12 / (length - 1);
    }
  };
  // Define the inline style for hover
  const getCardStyle = (cardName, length) => ({
    display: "flex",
    width:
      windowWidth <= 1060
        ? "100%"
        : length >= 4
        ? "100%"
        : length >= 3
        ? "80%"
        : length >= 2
        ? "60%"
        : "30% ",
    alignItems: "center",

    justifyContent: "center",
    backgroundColor: hoveredCard === cardName ? "#11cdef" : "transparent",
    color: hoveredCard === cardName ? "white" : "black",
    transition: "background-color 0.3s ease, color 0.3s ease",
    textAlign: "center",
  });

  // Define the inline style for button
  const getButtonStyle = (cardName) => ({
    backgroundColor: hoveredCard === cardName ? "#fb6340" : "#11cdef",
  });

  const createUserOrder = async (ID, planID, order, total) => {
    setLoading(true);
    try {
      let res;
      if (order === "subscription") {
        res = await createOrder({ plan_id: ID, currency: activeCurrency });
      } else {
        const data = {
          purchasedPlanDetails: selectedPlan,
          grandTotal: parseFloat(total),
          currency: activeCurrency,
        };
        res = await razorpayTopup(data);
      }
      const data = res.data;
      if (res.code === 200 && data.PaymentGateway === "RAZORPAY") {
        setLoading(false);
        if (order === "subscription") {
          const options = {
            key: data.APIKey,
            currency: data.Currency,
            subscription_id: data.ID,
            name: data.CompanyName,
            description: data.Description,
            handler: async function (response) {
              const planData = {
                razorpay_subscription_id: data.ID,
                recurring: "YES",
                subscription_id: data.ID,
                paymentGateway: data.PaymentGateway,
                plan_id: ID,
                razorpay_payment_id: response.razorpay_payment_id,
              };
              setLoading(true);
              const res = await paypalPlanSubscription(planData);
              if (res.code === 200) {
                setLoading(false);
                planList(dispatch);
                renewalRequired(dispatch);
                setOpenDowngrade(false);
                notify("success", res.message);
              } else {
                setLoading(false);
                planList(dispatch);
                renewalRequired(dispatch);
                setOpenDowngrade(false);
                notify("error", res.message);
              }
            },
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        } else {
          const options = {
            key: data.APIKey, // Razorpay API Key
            currency: data.Currency,
            order_id: data.OrderID,
            name: data.CompanyName,
            description: data.Description,
            handler: async function (response) {
              const requestData = {
                orderID: data.OrderID,
                paymentGateway: data.PaymentGateway,
                paymentID: response.razorpay_payment_id,
              };
              setLoading(true);
              const res = await checkTopUpStatus(requestData);
              if (res.code === 200) {
                setLoading(false);
                planList(dispatch);
                renewalRequired(dispatch);
                setOpenDowngrade(false);
                setTopUp(false);
                notify("success", res.message);
              } else {
                setLoading(false);
                planList(dispatch);
                renewalRequired(dispatch);
                setOpenDowngrade(false);
                notify("error", res.message);
              }
            },
          };
          const rzp = new window.Razorpay(options);
          rzp.open();
        }
      } else if (res.code === 200 && data.PaymentGateway === "PAYPAL") {
        setLoading(false);
        setPaypalModal(true);
        setPlanID(ID);
        setPaypalPlanID(planID);
        setOrderFlag(order);
      } else {
        setLoading(false);
        notify("error", res.message);
      }
    } catch (error) {
      setLoading(false);
      notify("error", res.message);
    }

    // }
  };

  // const paymentStatus = async (ID) => {
  //   const res = await checkPaymentStatus(ID);
  //   setLoading(false);
  //   if (res.data === "authorized" || res.data === "created") {
  //     paymentStatus(ID);
  //   } else if (res.code === 200) {
  //     setLoading(false);
  //     planList(dispatch);
  //     renewalRequired(dispatch);
  //     setOpenDowngrade(false);
  //     notify("success", res.message);
  //     return;
  //   } else {
  //     setLoading(false);
  //     planList(dispatch);
  //     renewalRequired(dispatch);
  //     setOpenDowngrade(false);
  //     notify("error", res.message);
  //     return;
  //   }
  // };

  const handleClick = () => {
    setRotated(!rotated);
  };
  // const handleModal = (planID) => {
  //   setOpenDowngrade(true);
  //   setPlanID(planID);
  // };

  const handleTopUp = () => {
    setSelectedPlan([
      {
        planID: planData?.topUpPlan[2].ID,
        price:
          activeCurrency === USD
            ? planData?.topUpPlan[2].usdRate
            : planData?.topUpPlan[2].inrRate,
        quantity: 1,
      },
    ]);
    setTopUp(!topUp);
  };
  const changePlan = () => {
    setChangePlanModal(!changePlanModal);
  };

  const getDays = (date) => {
    const currentDate = new Date();
    const future = new Date(date);

    if (isNaN(future.getTime())) {
      return; // Check for invalid date input
    }

    // Calculate the difference in milliseconds
    const diffInMs = future - currentDate;

    // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 ms)
    const diffInDays = Math.ceil(diffInMs / (1000 * 60 * 60 * 24));

    return diffInDays;
  };

  const handleActive = (value) => {
    setActiveCurrency(value);
  };

  const closeModal = () => {
    setPaypalModal(false);
  };

  //styles class
  const styles = {
    transition: {
      maxHeight: rotated ? "500px" : "0px",
      overflow: "hidden",
      transition: "max-height 0.5s ease-in-out",
      marginTop: "1rem",
    },
    border: {
      borderTop: "2px solid #868787",
      borderColor: "#BCBCBC",
    },
    planSpinner: {
      fontSize: "0.6rem",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "40vh", // Full viewport height
      width: "100%", // Full viewport width
    },
    boxStyle: {
      backgroundColor: "#11cdef",
      borderRadius: "1rem",
      height: "85%", // Adjust height for smaller screens
      padding: window.innerWidth <= 768 ? "20px" : "40px", // Smaller padding for mobile
      width: window.innerWidth <= 768 ? "100%" : "80%", // Adjust width for mobile view
    },
    h1: {
      fontSize: window.innerWidth <= 768 ? "40px" : "50px",
      color: "white",
    },
    h2: {
      fontSize: window.innerWidth <= 768 ? "12px" : "18px",
      color: "white",
    },
    popularWord: {
      position: "absolute",
      marginRight: "20px",
      top: "0px",
      right: "0",
      backgroundColor: "#FF6F61",
      color: "white",
      padding: "0px 8px",
      fontWeight: "bold",
      fontSize: "12px",
      boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    rotateIcon: {
      transition: "transform 0.3s ease",
      transform: "rotate(90deg)",
    },
    rotateIconReverse: {
      transition: "transform 0.3s ease",
      transform: "rotate(-90deg)",
    },

    backdrop: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent black
      zIndex: 9999, // High z-index to cover the whole content
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Centers the spinner
    },
    spinner: {
      fontSize: "2.5rem", // Adjust size if needed
      color: "white",
    },
    noteTextStyle: {
      marginBottom: "-15px",
      fontWeight: "normal",
      width: "100%",
      color: "red",
      fontSize: "0.875rem",
      // color: "#6c757d",
    },
    icon: {
      color: "green",
    },
    currencyIcon: { fontSize: "1.3rem" },
    loaderContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "50vh",
      width: "100%",
    },
  };

  // Handle checkbox toggle
  const handlePlanSelect = (planId, planPrice) => {
    setSelectedPlan((prevSelectedPlans) => {
      const exists = prevSelectedPlans.find((plan) => plan.planID === planId);
      if (exists) {
        if (prevSelectedPlans.length === 1) {
          notify("error", "At least one plan must be selected.");
          return prevSelectedPlans;
        }
        return prevSelectedPlans.filter((plan) => plan.planID !== planId);
      } else {
        return [
          ...prevSelectedPlans,
          { planID: planId, price: planPrice, quantity: 1 },
        ];
      }
    });
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {loading && (
        <div style={styles.backdrop}>
          <i
            className="fa fa-spinner fa-spin text-white"
            style={styles.spinner}
          />
        </div>
      )}
      <Warning />
      <Container className="mt--6" fluid>
        <Modal
          isOpen={paypalModal}
          // toggle={setPaypalModal(false)}
          centered={true}
          contentClassName={""}
        >
          <ModalHeader toggle={() => setPaypalModal(false)}>
            Paypal Payments
          </ModalHeader>
          <ModalBody>
            <PaypalButton
              closeModal={closeModal}
              paypalPlanID={paypalPlanID}
              planID={planID}
              setLoading={setLoading}
              notify={notify}
              clientID={planData.clientID}
              tenantID={planData.tenantID}
              orderFlag={orderFlag}
              selectedPlan={selectedPlan}
            />
          </ModalBody>
        </Modal>
        <Card>
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="d-flex align-items-center">
                Current Plan Summary
                <i className="fa fa-check-circle ml-2 " style={styles.icon}></i>
              </h3>
              <div>
                {planData.tenantActivePlan?.planID &&
                  planData.tenantActivePlan?.planID !== 1 && (
                    <Button color="warning" outline onClick={handleTopUp}>
                      Add More Credits
                    </Button>
                  )}
                {planData.tenantActivePlan?.planID &&
                planData.tenantActivePlan?.planID !== 1 &&
                !planData?.isPlanCancelled ? (
                  <UncontrolledDropdown nav>
                    <DropdownToggle className="nav-link pr-0" color="" tag="a">
                      <Media className="align-items-center">
                        <Media className="ml-2 d-flex d-lg-block align-items-center">
                          <span
                            className="btn-inner--icon ml-2 mt--1"
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fa fa-ellipsis-v" />
                          </span>
                        </Media>
                      </Media>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={() => changePlan()}>
                        <span>Cancel Subscription</span>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                ) : null}
              </div>
            </div>
            {planData.downGradePlan?.ID &&
            planData.tenantActivePlan?.planID &&
            planData.tenantActivePlan?.planID !== 1 ? (
              <h3 style={styles.noteTextStyle}>
                Notice : Your {planData.tenantActivePlan?.planName} plan will
                end on {planData.tenantActivePlan?.planRenewalDate} and you will
                be moved to the new {planData.downGradePlan?.name} plan.
              </h3>
            ) : renewal?.renewalRequired &&
              planData.tenantActivePlan?.planID &&
              planData.tenantActivePlan?.planID !== 1 ? (
              <h3 style={styles.noteTextStyle}>
                Notice : Your {planData.tenantActivePlan?.planName} Plan will
                expire in {getDays(planData.tenantActivePlan?.planRenewalDate)}{" "}
                days, Renew now to continue enjoying all your benefits without
                interruption!
              </h3>
            ) : planData?.isPlanCancelled &&
              planData.tenantActivePlan?.planID &&
              planData.tenantActivePlan?.planID !== 1 ? (
              <h3 style={styles.noteTextStyle}>
                Notice : Your {planData.tenantActivePlan?.planName} plan will be
                converted to Free Plan in{" "}
                {getDays(planData.tenantActivePlan?.planRenewalDate)} days.
              </h3>
            ) : null}
          </CardHeader>
          <CardBody>
            {planData.tenantActivePlan ? (
              <>
                <Row md={12}>
                  <Col md={4}>
                    <Label>Plan Name</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planName}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Billing Cycle</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planType === "STANDARD"
                        ? "Monthly"
                        : "Custom"}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Plan Cost</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planUsdRate
                        ? activeCurrency === USD
                          ? `$${planData.tenantActivePlan?.planUsdRate}`
                          : `₹${planData.tenantActivePlan?.planInrRate}`
                        : "Free"}{" "}
                    </p>
                  </Col>
                </Row>
                <Row md={12} className="mt-4">
                  <Col md={4}>
                    <Label>Allocated Credits</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.allocatedCredits}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Used Credits</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.UsedCredits}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Remaining Credits</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.availableCredits}
                    </p>
                  </Col>
                </Row>
                <Row md={12} className="mt-4">
                  <Col md={4}>
                    <Label>API support</Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.apiAllowed}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Last Renewed on </Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planStartDate}
                    </p>
                  </Col>
                  <Col md={4}>
                    <Label>Next Renewal on </Label>
                    <p className="mt--2">
                      {planData.tenantActivePlan?.planRenewalDate}
                    </p>
                  </Col>
                </Row>
              </>
            ) : planData?.code ? (
              <div style={styles.loaderContainer}>Something went wrong</div>
            ) : (
              <div style={styles.planSpinner}>
                <i className="fa fa-spinner fa-spin fa-3x text-info" />
              </div>
            )}
          </CardBody>
          {planData.tenantActivePlan?.planID !== 1 &&
          renewal?.renewalRequired ? (
            <CardFooter className="d-flex justify-content-end">
              <Button
                color="info"
                onClick={() =>
                  createUserOrder(
                    planData.tenantActivePlan?.planID,
                    0,
                    "subscription"
                  )
                }
              >
                Renew
              </Button>
            </CardFooter>
          ) : null}
        </Card>
        {renewal?.dongradeRequired &&
        planData.tenantActivePlan?.planID !== 1 ? (
          <Card>
            <CardHeader>
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="d-flex align-items-center">
                  Downgraded Plan Summary
                  {/* <i className="fa fa-check-circle ml-2 " style={styles.icon}></i> */}
                </h3>

                {/* <Button color="info" outline onClick={handleTopUp}>
              Add More Credit
              </Button> */}
                {/* {planData.tenantActivePlan?.planID === 1 ? null : (
                <UncontrolledDropdown nav>
                  <DropdownToggle className="nav-link pr-0" color="" tag="a">
                    <Media className="align-items-center">
                      <Media className="ml-2 d-flex d-lg-block align-items-center">
                        <span
                          className="btn-inner--icon ml-2 mt--1"
                          style={{ cursor: "pointer" }}
                        >
                          <i className="fa fa-ellipsis-v" />
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem onClick={() => changePlan()}>
                      <span>Cancel Subscription</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )} */}
              </div>
              <h3 style={styles.noteTextStyle}>
                Notice : Please Pay for your {planData.downGradePlan?.name}{" "}
                otherwise the plan will converted into Free plan in{" "}
                {getDays(planData.tenantActivePlan?.planRenewalDate)} days
              </h3>
            </CardHeader>
            <CardBody>
              <Row md={12}>
                <Col md={4}>
                  <Label>Plan Name</Label>
                  <p className="mt--2">{planData.downGradePlan?.name}</p>
                </Col>
                <Col md={4}>
                  <Label>Billing Cycle</Label>
                  <p className="mt--2">
                    {planData.downGradePlan?.type === "STANDARD"
                      ? "Monthly"
                      : "Custom"}
                  </p>
                </Col>
                <Col md={4}>
                  <Label>Plan Cost</Label>
                  <p className="mt--2">
                    {planData.tenantActivePlan?.planUsdRate
                      ? activeCurrency === USD
                        ? `$${planData.tenantActivePlan?.planUsdRate}`
                        : `₹${planData.tenantActivePlan?.planInrRate}`
                      : "Free"}{" "}
                  </p>
                </Col>
              </Row>
              <Row md={12} className="mt-4">
                <Col md={4}>
                  <Label>Allocated Credits</Label>
                  <p className="mt--2">{planData.downGradePlan?.credits}</p>
                </Col>
                {/* <Col md={4}>
                <Label>Used Credits</Label>
                <p className="mt--2">
                  {planData.downGradePlan?.UsedCredits}
                </p>
              </Col> */}
                {/* <Col md={4}>
                <Label>Remaining Credits</Label>
                <p className="mt--2">
                  {planData.downGradePlan?.availableCredits}
                </p>
              </Col> */}
                {/* </Row>
            <Row md={12} className="mt-4"> */}
                <Col md={4}>
                  <Label>API support</Label>
                  <p className="mt--2">
                    {planData.downGradePlan?.apiAllowed
                      ? planData.downGradePlan?.apiAllowed
                      : "NO"}{" "}
                  </p>
                </Col>
                {/* <Col md={4}>
                <Label>Last Renewed on </Label>
                <p className="mt--2">{planData.downGradePlan?.planStartDate}</p>
              </Col>
              <Col md={4}>
                <Label>Renewal on </Label>
                <p className="mt--2">
                  {planData.downGradePlan?.planID !== 1
                    ? planData.downGradePlan?.planRenewalDate
                    : "NA"}
                </p>
              </Col> */}
              </Row>
            </CardBody>
            {activeCurrency === INR && (
              <CardFooter className="d-flex justify-content-end">
                <Button
                  color="info"
                  onClick={() =>
                    createUserOrder(
                      planData.downGradePlan?.ID,
                      0,
                      "subscription"
                    )
                  }
                >
                  Pay Now
                </Button>
              </CardFooter>
            )}
          </Card>
        ) : null}
        <Card className="mb-4">
          <CardHeader>
            <div className="d-flex justify-content-between align-items-center">
              <h3 className="d-flex align-items-center">Plans</h3>

              {planData.tenantActivePlan?.planID === 1 && (
                <ButtonGroup>
                  <Button
                    className={
                      activeCurrency === USD ? "bg-warning text-white" : ""
                    }
                    color="secondary"
                    type="button"
                    onClick={() => handleActive(USD)}
                  >
                    $ USD
                  </Button>
                  <Button
                    className={
                      activeCurrency === INR ? "bg-warning text-white" : ""
                    }
                    color="secondary"
                    type="button"
                    onClick={() => handleActive(INR)}
                  >
                    ₹ INR
                  </Button>
                </ButtonGroup>
              )}
            </div>
          </CardHeader>
          <CardBody>
            <Row md={12} sm={12} xs={12}>
              {planData.plansAfterActivePlan?.map((plan) => (
                <Col
                  md={getColumnSize(
                    planData.plansAfterActivePlan?.length,
                    windowWidth
                  )}
                  lg={getColumnSize(
                    planData.plansAfterActivePlan?.length,
                    windowWidth
                  )}
                  sm={12}
                  xs={12}
                  key={plan.ID}
                  className="d-flex justify-content-center"
                >
                  {plan.name === "Testing plan" && plan.usdRate === 1 ? null : (
                    <>
                      <Card
                        md={4}
                        lg={3}
                        style={getCardStyle(
                          plan.name,
                          planData.plansAfterActivePlan.length <= 4
                            ? planData.plansAfterActivePlan.length - 1
                            : planData.plansAfterActivePlan.length
                        )}
                        onMouseEnter={() => handleMouseEnter(plan.name)}
                        onMouseLeave={handleMouseLeave}
                      >
                        {plan.popular === "YES" && (
                          <div style={styles.popularWord}>
                            <span>P</span>
                            <span>O</span>
                            <span>P</span>
                            <span>U</span>
                            <span>L</span>
                            <span>A</span>
                            <span>R</span>
                          </div>
                        )}

                        <div className="mt-3">
                          {plan.name}
                          <Divider className="mt-3" />
                        </div>
                        <CardBody className="p-2">
                          <h1
                            style={{
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            {activeCurrency === INR ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"
                                  style={styles.currencyIcon}
                                />
                                {plan.inrRate}
                                <strong
                                  style={{
                                    fontSize: "1rem",
                                    position: "relative",
                                    top: "-0.5rem", // Adjust the value as needed
                                  }}
                                >
                                  *
                                </strong>
                              </>
                            ) : (
                              <>${plan.usdRate}</>
                            )}
                            <p>Per Month</p>
                          </h1>
                          <Divider className="mb-4" />
                          <div
                            color="secondary"
                            className="fs-5 d-flex justify-content-left align-items-center "
                            style={{
                              // fontWeight: hoveredCard === plan.name ? "bold" : "",
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            <Col>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.credits} Credits
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.creditsCarryForward} Credits carry forward
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.campaignsLimit}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.subUsersLimit}
                              </Row>

                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature1}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature2}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {activeCurrency === INR
                                  ? plan.ratePerInviteInr
                                  : plan.ratePerInvite}{" "}
                                {activeCurrency === INR
                                  ? "INR/Credit"
                                  : "USD/Credit"}
                              </Row>
                            </Col>
                          </div>
                        </CardBody>
                        <CardActions>
                          <Button
                            color="info"
                            className="mb-3 mt--3"
                            style={getButtonStyle(plan.name)}
                            onClick={() =>
                              createUserOrder(
                                plan.ID,
                                plan.paypalPlanID,
                                "subscription"
                              )
                            }
                          >
                            Upgrade
                          </Button>
                        </CardActions>
                      </Card>
                    </>
                  )}
                </Col>
              ))}
            </Row>

            {email === "priyanka.c@scalent.io" ||
            email === "pankaj.khairnar@gmail.com" ? (
              <>
                {planData.plansAfterActivePlan?.map((plan) => (
                  <Col
                    md={3}
                    lg={3}
                    sm={12}
                    xs={12}
                    key={plan.ID}
                    className="d-flex justify-content-start"
                  >
                    {plan.name === "Testing plan" && plan.usdRate === 1 ? (
                      <Card
                        md={3}
                        lg={3}
                        style={getCardStyle(plan.name, 4)}
                        onMouseEnter={() => handleMouseEnter(plan.name)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <div className="mt-3">
                          {plan.name}
                          <Divider className="mt-3" />
                        </div>
                        <CardBody className="p-2">
                          <h1
                            style={{
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            {activeCurrency === INR ? (
                              <>
                                <i
                                  className="fa fa-inr"
                                  aria-hidden="true"
                                  style={styles.currencyIcon}
                                />
                                {plan.inrRate}
                              </>
                            ) : (
                              <>${plan.usdRate}</>
                            )}
                            <p>Per Month</p>
                          </h1>
                          <Divider className="mb-4" />
                          <div
                            color="secondary"
                            className="fs-5 d-flex justify-content-left align-items-center "
                            style={{
                              // fontWeight: hoveredCard === plan.name ? "bold" : "",
                              color:
                                hoveredCard === plan.name ? "white" : "black",
                            }}
                          >
                            <Col>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.credits} Credits
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.creditsCarryForward} Credits carry forward
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.campaignsLimit}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.subUsersLimit}
                              </Row>

                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature1}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {plan.feature2}
                              </Row>
                              <Row className="d-flex justify-content-left align-items-center mb-2">
                                <i
                                  className="fa fa-check-circle mr-2 fa-lg"
                                  style={{
                                    color:
                                      hoveredCard === plan.name
                                        ? "white"
                                        : "#11cdef",
                                  }}
                                ></i>
                                {activeCurrency === INR
                                  ? plan.ratePerInviteInr
                                  : plan.ratePerInvite}{" "}
                                {activeCurrency === INR
                                  ? "INR/Credit"
                                  : "USD/Credit"}
                              </Row>
                            </Col>
                          </div>
                        </CardBody>
                        <CardActions>
                          <Button
                            color="info"
                            className="mb-3 mt--3"
                            style={getButtonStyle(plan.name)}
                            onClick={() =>
                              createUserOrder(
                                plan.ID,
                                plan.paypalPlanID,
                                "subscription"
                              )
                            }
                          >
                            Upgrade
                          </Button>
                        </CardActions>
                      </Card>
                    ) : null}
                  </Col>
                ))}
              </>
            ) : null}

            <Row md={12} className="border solid 1px rounded">
              <Col md={6} className="p-6">
                <h3> What's Included</h3>
                <div color="secondary" className="fs-5 mt-3">
                  <Row>
                    <Col>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Full control
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Full customization
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Unlimited credits
                      </p>
                    </Col>
                    <Col>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Unlimited campaign
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        On call support
                      </p>
                      <p className="mb-1">
                        <i
                          className="fa fa-check-circle mr-2"
                          style={styles.icon}
                        ></i>
                        Customization option
                      </p>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col md={5} className="mt-5 mb-3 d-flex justify-content-center">
                <div
                  className="border d-flex flex-column justify-content-center align-items-center "
                  style={styles.boxStyle}
                >
                  <h1 style={styles.h1} className="text-center">
                    Self Hosted
                  </h1>
                  <h3 style={styles.h2} className="text-center">
                    Deploy on your Owned Server
                  </h3>
                  <h2 style={styles.h2} className="text-center">
                    Contact sales on&nbsp;
                    <a
                      href="mailto:sales@bulkcalendar.co"
                      style={{
                        textDecoration: isHovered ? "underline" : "none",
                        color: "inherit",
                      }}
                      onMouseEnter={() => setIsHovered(true)}
                      onMouseLeave={() => setIsHovered(false)}
                    >
                      sales@bulkcalendar.co
                    </a>
                  </h2>
                </div>
              </Col>
            </Row>

            <Row md={12} className="d-flex justify-content-start ml-2 mt-3">
              {planData?.plansBeforeActivePlan?.length === 0 ? null : (
                <h4
                  style={{ color: "#11cdef", cursor: "pointer" }}
                  onClick={handleClick}
                >
                  <br />
                  More Plans
                  <i
                    className={`fa fa-caret-right ml-1`}
                    style={
                      rotated ? styles.rotateIcon : styles.rotateIconReverse
                    }
                  ></i>
                </h4>
              )}
            </Row>

            {/* Smooth Drop-down Effect */}
            <div style={styles.transition}>
              <Row md={12}>
                {planData.plansBeforeActivePlan?.map((plan) => (
                  <Col
                    md={12 % planData.plansBeforeActivePlan.length}
                    key={plan.ID}
                    className="d-flex justify-content-center"
                  >
                    <Card
                      md={3}
                      style={getCardStyle(
                        plan.name,
                        planData.plansBeforeActivePlan.length
                      )}
                      onMouseEnter={() => handleMouseEnter(plan.name)}
                      onMouseLeave={handleMouseLeave}
                    >
                      {plan.popular === "YES" && (
                        <div style={styles.popularWord}>
                          <span>P</span>
                          <span>O</span>
                          <span>P</span>
                          <span>U</span>
                          <span>L</span>
                          <span>A</span>
                          <span>R</span>
                        </div>
                      )}

                      <div className="mt-3">
                        {plan.name}
                        <Divider className="mt-3" />
                      </div>
                      <CardBody>
                        <h1
                          style={{
                            color:
                              hoveredCard === plan.name ? "white" : "black",
                          }}
                        >
                          {activeCurrency === INR ? (
                            <>
                              <i
                                className="fa fa-inr"
                                aria-hidden="true"
                                style={styles.currencyIcon}
                              />
                              {plan.inrRate}
                            </>
                          ) : (
                            <>${plan.usdRate}</>
                          )}
                          <p>Per Month</p>
                        </h1>
                        <Divider className="mb-4" />

                        <div
                          color="secondary"
                          className="fs-5 d-flex justify-content-left align-items-center"
                          style={{
                            // fontWeight: hoveredCard === plan.name ? "bold" : "",
                            color:
                              hoveredCard === plan.name ? "white" : "black",
                          }}
                        >
                          <Col>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.credits} Credits
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.creditsCarryForward} Credits carry forward
                            </Row>

                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.campaignsLimit}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.subUsersLimit}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.feature1}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center mb-2">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {plan.feature2}
                            </Row>
                            <Row className="d-flex justify-content-left align-items-center">
                              <i
                                className="fa fa-check-circle mr-2 fa-lg"
                                style={{
                                  color:
                                    hoveredCard === plan.name
                                      ? "white"
                                      : "#11cdef",
                                }}
                              ></i>
                              {activeCurrency === INR
                                ? plan.ratePerInviteInr
                                : plan.ratePerInvite}{" "}
                              {activeCurrency === INR
                                ? "INR/Credit"
                                : "USD/Credit"}
                            </Row>
                          </Col>
                        </div>
                      </CardBody>
                      <CardActions>
                        <Button
                          color="info"
                          className="m-3 mt--4"
                          style={getButtonStyle(plan.name)}
                          onClick={() =>
                            createUserOrder(
                              plan.ID,
                              plan.paypalPlanID,
                              "subscription"
                            )
                          }
                        >
                          Downgrade
                        </Button>
                      </CardActions>
                    </Card>
                  </Col>
                ))}
              </Row>
            </div>

            {activeCurrency === INR && (
              <div className="text-md">* 18% GST will be applicable.</div>
            )}
          </CardBody>

          <CancelPlanModal
            changePlanModal={changePlanModal}
            changePlan={changePlan}
          />
          <Modal
            isOpen={topUp}
            // toggle={handleTopUp}
            // className="modal-dialog-centered modal-secondary"
            centered={true}
            contentClassName={""}
          >
            <ModalHeader toggle={handleTopUp}> Top-up Plans</ModalHeader>
            <ModalBody>
              <div className="plan-list">
                <Row sm="12">
                  {planData.topUpPlan?.map((plan) => (
                    <Col
                      sm="6"
                      key={plan.ID}
                      className="mb-3 d-flex align-items-center pl-4"
                    >
                      {/* Checkbox outside the card, vertically centered */}

                      {/* Card */}
                      <Card
                        className={`position-relative m-0 flex-grow-1  ${
                          selectedPlan.some(
                            (addedPlan) => addedPlan.planID === plan.ID
                          )
                            ? "bg-info text-white"
                            : ""
                        }`}
                        onClick={() =>
                          handlePlanSelect(
                            plan.ID,
                            activeCurrency === INR ? plan.inrRate : plan.usdRate
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <CardBody>
                          <Row className="d-flex align-items-center">
                            <div className="ml-4 mt--4">
                              <Input
                                style={{ cursor: "pointer" }}
                                type="checkbox"
                                readOnly
                                checked={selectedPlan.some(
                                  (addedPlan) => addedPlan.planID === plan.ID
                                )}
                              />
                            </div>
                            <Col>
                              <Row>
                                <Col>
                                  <strong>{plan.credits} Credits</strong>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="text-end">
                                  <span>
                                    {activeCurrency === INR ? (
                                      <span>
                                        <i
                                          className="fa fa-inr text-sm"
                                          style={{ marginRight: "4px" }}
                                        />
                                        {plan?.inrRate
                                          ? parseFloat(
                                              plan.inrRate
                                            ).toLocaleString("en-US", {
                                              minimumFractionDigits: 2,
                                              maximumFractionDigits: 2,
                                            })
                                          : "N/A"}
                                      </span>
                                    ) : (
                                      <span>${plan.usdRate}</span>
                                    )}
                                  </span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </Col>
                  ))}
                </Row>

                <div className="d-flex justify-content-center flex-column align-items-center">
                  {selectedPlan.map((addedPlan, index) => {
                    const matchingPlan = planData.topUpPlan?.find(
                      (plan) => plan.ID === addedPlan.planID
                    );
                    return (
                      <Row key={index} className="mb-3  w-100">
                        <Col
                          xs={6}
                          className="text-end d-flex justify-content-start align-items-center"
                        >
                          {matchingPlan?.credits || 0} Credits
                        </Col>
                        <Col
                          xs={6}
                          className="text-end d-flex justify-content-end align-items-center"
                        >
                          {activeCurrency === INR ? (
                            <i className="fa fa-inr text-sm" />
                          ) : (
                            "$"
                          )}
                          {addedPlan?.price
                            ? parseFloat(addedPlan.price).toLocaleString(
                                "en-US",
                                {
                                  minimumFractionDigits: 2,
                                  maximumFractionDigits: 2,
                                }
                              )
                            : "N/A"}
                        </Col>
                      </Row>
                    );
                  })}
                  {selectedPlan.length !== 0 && activeCurrency === INR && (
                    <>
                      <Row className="mb-2 pt-2 w-100" style={styles.border}>
                        <Col
                          xs={6}
                          className="text-end d-flex justify-content-start align-items-center"
                        >
                          Sub Total
                        </Col>
                        <Col
                          xs={6}
                          className="text-end d-flex justify-content-end align-items-center"
                        >
                          <i className="fa fa-inr text-sm" />
                          {selectedPlan
                            .reduce(
                              (total, plan) => total + (plan.price || 0),
                              0
                            )
                            .toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                        </Col>
                      </Row>

                      <Row className="mb-2 w-100">
                        <Col
                          xs={6}
                          className="text-end d-flex justify-content-start align-items-center"
                        >
                          GST 18%
                        </Col>
                        <Col
                          xs={6}
                          className="text-end d-flex justify-content-end align-items-center"
                        >
                          <i className="fa fa-inr text-xs" />
                          {(
                            selectedPlan.reduce(
                              (total, plan) => total + (plan.price || 0),
                              0
                            ) * 0.18
                          ).toLocaleString("en-US", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })}
                        </Col>
                      </Row>
                    </>
                  )}
                  <Row className="mb-3 w-100 pt-2" style={styles.border}>
                    <Col
                      xs={6}
                      className="text-end d-flex justify-content-start align-items-center"
                    >
                      <strong>
                        Total Credits :{" "}
                        {selectedPlan
                          .reduce((total, addedPlan) => {
                            const matchingPlan = planData.topUpPlan?.find(
                              (plan) => plan.ID === addedPlan.planID
                            );
                            const credits = matchingPlan?.credits
                              ? parseFloat(
                                  matchingPlan.credits.replace(/,/g, "")
                                )
                              : 0;
                            return total + credits;
                          }, 0)
                          .toLocaleString("en-US")}
                      </strong>
                    </Col>
                    <Col
                      xs={6}
                      className="text-end d-flex justify-content-end align-items-center"
                    >
                      <strong>
                        Total :{" "}
                        {activeCurrency === INR ? (
                          <>
                            <i className="fa fa-inr text-sm" />
                            {(
                              selectedPlan.reduce(
                                (total, plan) => total + (plan.price || 0),
                                0
                              ) * 1.18
                            ).toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}
                          </>
                        ) : (
                          `$${selectedPlan
                            .reduce(
                              (total, plan) => total + (plan.price || 0),
                              0
                            )
                            .toLocaleString("en-US", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })}`
                        )}
                      </strong>
                    </Col>
                  </Row>
                </div>
                {/* <Row className="d-flex align-items-center justify-content-between"> */}
                {/* <Col xs="auto"> */}
                {/* </Col> */}
                {/* <Col xs="auto"> */}
                <Button
                  color="info"
                  className="w-100"
                  onClick={() =>
                    createUserOrder(
                      0,
                      0,
                      "capture",
                      activeCurrency === INR
                        ? selectedPlan.reduce(
                            (total, plan) => total + (plan.price || 0),
                            0
                          ) * (1.18).toFixed(2)
                        : selectedPlan
                            .reduce(
                              (total, plan) => total + (plan.price || 0),
                              0
                            )
                            .toFixed(2)
                    )
                  }
                >
                  Pay{" "}
                  {planData.tenantActivePlan?.currency === INR ? (
                    <>
                      <i className="fa fa-inr text-xs" />
                      {(
                        selectedPlan.reduce(
                          (total, plan) => total + (plan.price || 0),
                          0
                        ) * 1.18
                      ).toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </>
                  ) : (
                    `$${selectedPlan
                      .reduce((total, plan) => total + (plan.price || 0), 0)
                      .toLocaleString("en-US", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}`
                  )}
                </Button>
                <span className="mt-2 text-sm">
                  Note: Credits will be carry forward as per your plan.
                </span>
                {/* </Col> */}
                {/* </Row> */}
              </div>
            </ModalBody>
          </Modal>
          {/* <Modal
            isOpen={openDowngrade}
            className="modal-dialog-centered modal-secondary"
          >
            <ModalHeader>Downgrade plan</ModalHeader>
            <ModalBody>
              <div className="plan-list">
                Please note that this plan will be activated once your current
                plan expires. You'll continue to enjoy the benefits of your
                existing plan until the end of its term, after which the new
                plan will seamlessly take effect, ensuring uninterrupted
                service.
              </div>
              <ModalFooter>
                <Button
                  color="secondary"
                  onClick={() => setOpenDowngrade(false)}
                >
                  Back
                </Button>
                <Button color="info" onClick={() => downgradePlan(planID)}>
                  It's Fine
                </Button>
              </ModalFooter>
            </ModalBody>
          </Modal> */}
        </Card>
      </Container>
    </>
  );
}

export default Plans;
