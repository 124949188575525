import React, { useState, useEffect } from "react";
import { Card, CardBody, CardHeader, Row, Col, Button } from "reactstrap";

const ErrorBoundary = (props) => {
  const [hasError, setHasError] = useState(false);

  const styles = {
    errorCard: {
      textAlign: "center",
    },
    thankYouMessage: {
      fontSize: "1rem",
      color: props.color,
    },
    container: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
      marginTop: "1rem",
      marginBottom: "-3rem",
    },
    image: {
      width:
        window.innerWidth <= 576
          ? "40px"
          : window.innerWidth <= 768
          ? "50px"
          : window.innerWidth <= 1200
          ? "40px"
          : "50px",
      height:
        window.innerWidth <= 576
          ? "35px"
          : window.innerWidth <= 768
          ? "45px"
          : window.innerWidth <= 1200
          ? "35px"
          : "45px",
    },
    text: {
      fontFamily: "rubik",
      color: "#40BBEC",
      fontSize:
        window.innerWidth <= 576
          ? "24px"
          : window.innerWidth <= 768
          ? "28px"
          : window.innerWidth <= 1200
          ? "24px"
          : "28px",
      fontWeight: 500,
      textTransform: "none",
      marginLeft: "5px",
      marginBottom: 0,
    },
    errorImg: {
      maxWidth: "100%", // Makes the image scale responsively
      height: "auto", // Maintains aspect ratio
      display: "block",
      margin: "0 auto", // Centers the image
    },
  };

  const refreshPage = () => {
    if (hasError) {
      window.location.reload();
    } else {
      return;
    }
  };

  const handleError = () => {
    setHasError(true);
  };
  useEffect(() => {
    window.addEventListener("error", handleError);
    window.addEventListener("unhandledrejection", (event) => {
      handleError({
        error: event.reason,
      });
    });

    return () => {
      window.removeEventListener("error", handleError);
      window.removeEventListener("unhandledrejection", handleError);
      setHasError(false);
    };
  }, []);

  if (hasError) {
    return (
      <Row className="justify-content-center mt-5">
        <Col md="4" lg="4">
          <Card style={styles.errorCard}>
            <div style={styles.container}>
              <img
                alt="..."
                className="navbar-brand-img align-items-center d-flex"
                src={require("assets/img/brand/bulk-logo.png")}
                style={styles.image}
              />
              <p style={styles.text}>BulkCalendar</p>
            </div>
            <div style={styles.container}>
              <img
                style={styles.errorImg}
                alt="..."
                className="navbar-brand-img align-items-center d-flex"
                src={require("assets/img/brand/calendarError.png")}
              />
            </div>

            <h1 className="mt-3">Something went wrong </h1>
            <div className="w-100 d-flex justify-content-center mb-3">
              <Button color="info" outline onClick={refreshPage}>
                Refresh Page
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }

  return props.children;
};

export default ErrorBoundary;
