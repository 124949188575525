import React from "react";
// reactstrap components
import { Card, CardBody, Button } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import NotificationAlert from "react-notification-alert";
import { useNavigate } from "react-router-dom";
import { cyanBlue } from "components/Common/constants";
import { useSelector } from "react-redux";
import { convertUTCToLocal } from "components/CommonFunctions";
import { useEffect, useState } from "react";
import { ROLES } from "components/Common/constants";

function SubUsersList(props) {
  const navigate = useNavigate();
  const role = JSON.parse(localStorage.getItem("p"))?.role;
  const subUsers = useSelector((state) => state.Dashboard.dashboardData);
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const notificationAlertRef = React.useRef(null);
  useEffect(() => {
    if (
      subUsers?.activeAndInvitedSubUsers &&
      subUsers?.activeAndInvitedSubUsers?.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (subUsers && subUsers?.activeAndInvitedSubUsers?.length === 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    } else if (subUsers.code) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
        error: true,
      });
    }
  }, [subUsers]);
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      {/* <SimpleHeader name="Company Name" /> */}

      <Card className="mb-4" style={styles.cardStyle}>
        <CardBody>
          <div md={12} style={styles.header}>
            <h3 className="mb-0">Sub Users</h3>
            {role === ROLES.tenantAdmin && (
              <div>
                <Button
                  color="warning"
                  className="px-2 py-1"
                  onClick={() =>
                    navigate("/a/sub-users", {
                      state: true,
                    })
                  }
                >
                  Add New
                </Button>
                <Button
                  color="info"
                  className="px-2 py-1"
                  onClick={() => navigate("/a/sub-users")}
                >
                  See all
                </Button>
              </div>
            )}
          </div>
          {showListAlternative.loader ? (
            <div style={styles.loaderContainer}>
              <i
                className="fa fa-spinner fa-spin fa-2xl"
                style={styles.loaderColor}
              />
            </div>
          ) : showListAlternative.error ? (
            <div style={styles.loaderContainer}>
              Something went wrong please try after some time.
            </div>
          ) : subUsers.activeAndInvitedSubUsers?.length !== 0 ? (
            <ToolkitProvider
              data={subUsers.activeAndInvitedSubUsers || []}
              keyField="email"
              columns={[
                {
                  dataField: "firstName",
                  text: "Name",
                  formatter: (cell) => {
                    return cell ? cell : "NA";
                  },
                },
                {
                  dataField: "email",
                  text: "Email",
                },
                {
                  dataField: "createdOn",
                  text:
                    //   row.status === "INVITED_SUB_USER"
                    //     ? "Invited At"
                    //     :
                    "Date",
                  formatter: (cell) => {
                    return convertUTCToLocal(cell); //to show the date in users local time
                  },
                },
                {
                  dataField: "status",
                  text: "Status",
                  // formatter: statusCellRenderer,
                },
              ]}
              search
            >
              {(props) => (
                <div className="py-4 table-responsive ">
                  <div className="d-flex justify-content-center mr-9"></div>
                  <BootstrapTable
                    {...props.baseProps}
                    bootstrap4={true}
                    // pagination={pagination}
                    bordered={false}
                  />
                </div>
              )}
            </ToolkitProvider>
          ) : showListAlternative.noRecordsFound ? (
            <div style={styles.loaderContainer}>No records found.</div>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
}

export default SubUsersList;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "35vh",
  },
  loaderColor: { color: cyanBlue },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardStyle: { height: "25rem", overflow: "hidden" },
};
